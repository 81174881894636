import React from 'react'
// import '../../../src/assets/js/main';
// icons 
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import services_data from '../../web_data/Web_data';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate()
    return (
        <footer id="footer" className="footer">

            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-5 col-md-12 footer-info">
                        <a href="index.html" className="logo d-flex align-items-center">
                            <span>Techmaze</span>
                        </a>
                        <p>At Techmaze, we're dedicated to delivering cutting-edge IT solutions that redefine industry standards. Our passionate team of experts combines creativity and technical prowess to craft bespoke software, elevate web experiences, and drive digital transformation. Partner with us to unlock innovation, navigate the digital landscape, and propel your business to new heights. Contact us today, and let's shape tomorrow together.</p>
                        <div className="social-links d-flex mt-4">
                            <a href="#" className="twitter"><i><FaTwitter /></i></a>
                            <a href="#" className="facebook"><i><FaFacebook /></i></a>
                            <a href="#" className="instagram"><i><FaInstagram /></i></a>
                            <a href="#" className="linkedin"><i><FaLinkedin /></i></a>
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><a href="#hero">Home</a></li>
                            <li><a href="#about">About us</a></li>
                            <li><a href="#services">Services</a></li>
                            <li><a href="#team">Team</a></li>
                            <li><a href="careers">Careers</a></li>
                            {/* <li><a href="#">Privacy policy</a></li> */}
                        </ul>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                            {services_data.slice(4).map((v, i) => {
                                return (
                                    <li key={i}><a style={{cursor:'pointer'}} onClick={() => navigate('/#services')}>{v.title}</a></li>
                                )
                            })}
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                        <h4>Contact Us</h4>
                        <p>
                            Suite No. 410, 4th Floor, DC-1,<br></br>
                            Block 09,<br></br>
                            Clifton Karachi.<br></br>
                            <strong>Phone:</strong> +92 03402117591<br></br>
                            <strong>Email:</strong> info@Techmazeglobal.com
                        </p>

                    </div>

                </div>
            </div>
        </footer>
    )
}

export default Footer