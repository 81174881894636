// import React, { useState } from "react";
// import useLocalStorage from "use-local-storage";

// import "./App.css";
// import { Toggle } from "./components/toggle/Toggle";

// function App() {
//   const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
//   const [isDark, setIsDark] = useLocalStorage("isDark", preference);
//   return (
//     <div className="App" data-theme={isDark ? "dark" : "light"}>
//       <Toggle isChecked={isDark} handleChange={() => setIsDark(!isDark)} />
//       <h1 className="title">Hello world!</h1>
//       <div className="box">
//         <h2>This is a box</h2>
//       </div>
//     </div>
//   )
// }

// export default App

import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/home/Home'
import { motion, useScroll } from "framer-motion";
import Careers from './pages/career/Careers';
import Jobs from './pages/career/Jobs';


function App() {
  const { scrollYProgress } = useScroll();
  return (
    <BrowserRouter>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />

      <Routes>
        <Route index element={<Home />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/jobs/:id' element={<Jobs />} />
      </Routes>

    </BrowserRouter>
  )
}

export default App