import React, { useState } from 'react'
// import '../../../src/assets/js/main';
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { CiClock2, CiLocationOn } from "react-icons/ci";
import { base_url } from '../utils/Constants';

function Contact() {
  const [isLoading, setIsloading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsloading(true)
        const response = await fetch(`${base_url}api/contactUs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        setIsloading(false)
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.success) {
            setFormData({
              name: '',
              email: '',
              subject: '',
              message: ''
            })
            alert("Email send successfully")
          }
        } else {
          alert("there is some issue please try again")
          console.error('Error:', response.statusText);
        }
      } catch (error) {
        setIsloading(false)
        alert("there is some issue please try again")
        console.error('Error:', error);
        // Handle network error
      }
    } else {
      // Handle form validation errors
      console.log('Form validation failed.');
    }
  };


  const validateForm = () => {
    let isValid = true;
    const errors = {};

    // Validate name
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    }

    // Validate email
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!isValidEmail(formData.email)) {
      errors.email = 'Invalid email address';
      isValid = false;
    }

    // Validate subject
    if (!formData.subject.trim()) {
      errors.subject = 'Subject is required';
      isValid = false;
    }

    // Validate message
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const isValidEmail = (email) => {
    // Simple email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Contact</h2>
          <p>Connect with Techmaze - Your gateway to seamless communication and tailored IT solutions.</p>
        </div>

        <div className="row gx-lg-0 gy-4">

          <div className="col-lg-4">

            <div className="info-container d-flex flex-column align-items-center justify-content-center">
              <div className="info-item d-flex">
                <i className="flex-shrink-0"><CiLocationOn /></i>
                <div>
                  <h4>Location:</h4>
                  <p style={{ justifyContent: 'normal' }}>Suite No. 410, 4th Floor, DC-1, Block 09, Clifton Karachi.</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="flex-shrink-0"><FaEnvelope /></i>
                <div>
                  <h4>Email:</h4>
                  <p>info@Techmazeglobal.com</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="flex-shrink-0"><FaPhone /></i>
                <div>
                  <h4>Call:</h4>
                  <p>+92 03402117591</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="flex-shrink-0"><CiClock2 /></i>
                <div>
                  <h4>Open Hours:</h4>
                  <p>Mon-Fri: 8AM - 5PM</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-8">
            <form className="php-email-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
                  {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
                  {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" value={formData.subject} onChange={handleChange} placeholder="Subject" required />
                {formErrors.subject && <div className="invalid-feedback">{formErrors.subject}</div>}
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" value={formData.message} onChange={handleChange} rows="7" placeholder="Message" required></textarea>
                {formErrors.message && <div className="invalid-feedback">{formErrors.message}</div>}
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              {isLoading &&
                <a className='text-center'>Email is sending</a>
              }
              <div className="text-center"><button disabled={isLoading ? true : false} type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>
  )
}

export default Contact