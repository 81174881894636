import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../components/header_footer/Footer';
import Header from '../../components/header_footer/Header';
import { Container, Row } from 'react-bootstrap';
import { CiLinkedin, CiMail, CiPhone, CiSearch, CiUser } from "react-icons/ci";
import { MdCastForEducation, MdFileCopy, MdOutlineWorkspaces } from "react-icons/md";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { base_url } from '../../utils/Constants';
import Uploading from '../../components/Uploading';
import { FiPlus, FiMinus } from "react-icons/fi";
import { TbFlag3Filled } from 'react-icons/tb';

function Jobs() {
    const [job, setJob] = useState(null);
    const { id } = useParams()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isError, setIsError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        linkedin: '',
        education: '',
        experience: '',
        cv: null
    });
    const [errors, setErrors] = useState({});
    const [openItem, setOpenItem] = useState(false);
    useEffect(() => {
        if (id) {
            const jobData = JSON.parse(sessionStorage.getItem("careersData"));
            if (jobData) {
                setJob(jobData.find(item => item.id == id))
            } else {
                setJob(null)
            }
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear error message when user starts typing
        setErrors(prevState => ({
            ...prevState,
            [name]: ''
        }));
    };

    const handleImageChange = (e) => {
        const { name, value } = e.target;
        const file = e.target.files[0];
        if (file) {
            // Update state with the selected image file
            setErrors(prevState => ({
                ...prevState,
                cv: ''
            }));
            setFormData(prevState => ({
                ...prevState,
                cv: file
            }));
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
            // Form is valid, proceed with submission
            try {
                setIsLoading(true)
                setIsError('')
                // Create FormData object
                const formDataToSend = new FormData();

                // Append form data from state
                formDataToSend.append('first_name', formData.firstName);
                formDataToSend.append('last_name', formData.lastName);
                formDataToSend.append('email', formData.email);
                formDataToSend.append('phone', formData.phone);
                formDataToSend.append('linkedin_profile', formData.linkedin);
                formDataToSend.append('education', formData.education);
                formDataToSend.append('experience', formData.experience);
                formDataToSend.append('post_id', id);
                formDataToSend.append('cv_file', formData.cv);

                // Make Axios POST request
                const response = await axios.post(`${base_url}api/register`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setTimeout(() => {
                    setIsLoading(false)
                    setIsSubmitted(true);
                }, 1500);
                if (response.data.success) {
                    setIsError('')
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        linkedin: '',
                        education: '',
                        experience: '',
                        cv: null
                    });
                }
                else {
                    setIsSubmitted(false)
                    setIsError(response.data.message)
                }
            } catch (error) {
                console.log(error)
                alert(error)
            }
        } else {
            setErrors(errors);
        }
    };

    const validateForm = (data) => {
        let errors = {};
        // Validate each field and populate errors object
        if (!data.firstName.trim()) {
            errors.firstName = "First name is required";
        }
        if (!data.lastName.trim()) {
            errors.lastName = "Last name is required";
        }
        if (!data.email.trim()) {
            errors.email = "Email is required";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Invalid email format";
        }
        if (!data.phone.trim()) {
            errors.phone = "Phone number is required";
        } else if (!isValidPhoneNumber(data.phone)) {
            errors.phone = "Invalid phone number format";
        }
        if (!data.linkedin.trim()) {
            errors.linkedin = "LinkedIn profile link is required";
        }
        if (!data.education.trim()) {
            errors.education = "Education details are required";
        }
        if (!data.experience.trim()) {
            errors.experience = "Experience details are required";
        }
        if (!data.cv) {
            errors.cv = "CV is required";
        } else {
            const allowedFormats = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            const maxSize = 2 * 1024 * 1024; // 2MB
            if (!allowedFormats.includes(data.cv.type)) {
                errors.cv = "Only pdf and word are allowed";
            } else if (data.cv.size > maxSize) {
                errors.cv = "file size should be less than 2MB";
            }
        }
        return errors;
    };

    const isValidEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhoneNumber = (phone) => {
        // Phone number validation regex
        const phoneRegex = /^\d{11}$/;
        return phoneRegex.test(phone);
    };

    return (
        <>
            <Header />
            <section className='career_section'>
                <div className='career_header'>
                    <Container className='d-flex align-items-center h-100'>
                        {job && <h1>{job.title}</h1>}
                    </Container>
                </div>

                {isSubmitted &&
                    <div className='center'>
                        <div className='modal_alert_career'>
                            <h4 className="alert-heading">Congratulations!</h4>
                            <p>Thank you for registering for the job opportunity. Your application has been successfully submitted.</p>

                            <p className="mb-0">We'll review your application and get back to you as soon as possible. In the meantime, feel free to explore more about our company and other available opportunities.</p>
                            <a style={{ marginTop: 40, fontWeight: '600', fontSize: 24 }} href='/'>OK</a>
                        </div>
                    </div>
                }

                <Container className='mt-5 mb-5'>
                    <Row>
                        <h3 style={{ fontWeight: 'bold' }} className='text-center'>Apply Now</h3>
                    </Row>
                    <Row>
                        <form onSubmit={handleSubmit}>
                            <div className='pt-4'>
                                <div className="row p-2">
                                    <div className="col-lg-6 col-md-12 pt-4">
                                        <div className="input-group mb-3 bottom-border align-items-center">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ height: 50 }}>
                                                    <CiUser className="align-middle" size={30} />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className={`form-control align-middle ${errors.firstName && 'is-invalid'}`}
                                                placeholder="First name"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                            />
                                            {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 pt-4">
                                        <div className="input-group mb-3 bottom-border align-items-center">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ height: 50 }}>
                                                    <CiUser className="align-middle" size={30} />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className={`form-control align-middle ${errors.lastName && 'is-invalid'}`}
                                                placeholder="Last name"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                            />
                                            {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Email */}
                            <div className='col-md-12 p-2 pt-4'>
                                <div className='input-group mb-3 bottom-border align-items-center'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text' style={{ height: 50 }}>
                                            <CiMail className='align-middle' size={30} />
                                        </span>
                                    </div>
                                    <input
                                        type='email'
                                        className={`form-control align-middle ${errors.email && 'is-invalid'}`}
                                        placeholder='Email address'
                                        name='email'
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                                </div>
                            </div>
                            {/* Phone */}
                            <div className='col-md-12 p-2 pt-4'>
                                <div className='input-group mb-3 bottom-border align-items-center'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text' style={{ height: 50 }}>
                                            <CiPhone className='align-middle' size={30} />
                                        </span>
                                    </div>
                                    <input
                                        type='text'
                                        className={`form-control align-middle ${errors.phone && 'is-invalid'}`}
                                        placeholder='ex 03xxxxxxxxx'
                                       maxLength={11}
                                        name='phone'
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                    {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                                </div>
                            </div>
                            {/* LinkedIn */}
                            <div className='col-md-12 p-2 pt-4'>
                                <div className='input-group mb-3 bottom-border align-items-center'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text' style={{ height: 50 }}>
                                            <CiLinkedin className='align-middle' size={30} />
                                        </span>
                                    </div>
                                    <input
                                        type='text'
                                        className={`form-control align-middle ${errors.linkedin && 'is-invalid'}`}
                                        placeholder='LinkedIn Profile Link'
                                        name='linkedin'
                                        value={formData.linkedin}
                                        onChange={handleChange}
                                    />
                                    {errors.linkedin && <div className='invalid-feedback'>{errors.linkedin}</div>}
                                </div>
                            </div>
                            {/* Education */}
                            <div className='col-md-12 p-2 pt-4'>
                                <div className='input-group mb-3 bottom-border align-items-center'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text' style={{ height: 50 }}>
                                            <MdCastForEducation className='align-middle' size={30} />
                                        </span>
                                    </div>
                                    <input
                                        type='text'
                                        className={`form-control align-middle ${errors.education && 'is-invalid'}`}
                                        placeholder='Education'
                                        name='education'
                                        value={formData.education}
                                        onChange={handleChange}
                                    />
                                    {errors.education && <div className='invalid-feedback'>{errors.education}</div>}
                                </div>
                            </div>
                            {/* Experience */}
                            <div className='col-md-12 p-2 pt-4'>
                                <div className='input-group mb-3 bottom-border align-items-center'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text' style={{ height: 50 }}>
                                            <MdOutlineWorkspaces className='align-middle' size={30} />
                                        </span>
                                    </div>
                                    <input
                                        type='text'
                                        className={`form-control align-middle ${errors.experience && 'is-invalid'}`}
                                        placeholder='Experience'
                                        name='experience'
                                        value={formData.experience}
                                        onChange={handleChange}
                                    />
                                    {errors.experience && <div className='invalid-feedback'>{errors.experience}</div>}
                                </div>
                            </div>
                            {/* CV */}
                            <div className='col-md-12 p-2 pt-4'>
                                <div className='input-group mb-3 bottom-border align-items-center'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text' style={{ height: 50 }}>
                                            <MdFileCopy className='align-middle' size={30} />
                                        </span>
                                    </div>
                                    <input
                                        className={`form-control align-middle ${errors.cv && 'is-invalid'}`}
                                        type="file"
                                        id="image"
                                        name="image"
                                        accept="application/pdf, application/msword"
                                        onChange={handleImageChange}
                                    />
                                    {errors.cv && <div className='invalid-feedback'>{errors.cv}</div>}
                                </div>
                            </div>


                            {/* Submit button */}
                            <div className="d-flex">
                                <button type="submit" className="registerCareer">Complete registration</button>
                            </div>
                        </form>
                    </Row>
                </Container>
                {isError &&
                    <div className="container alert alert-danger" role="alert" id="alertSuccess">
                        <h4 className="alert-heading">Error!</h4>
                        <p>{isError}</p>
                    </div>
                }
                <Container className='mt-5 mb-5'>
                    <Row style={{ borderBottom: '1px solid #ced4da' }}>

                        {job && (
                            <div>
                                {job.description &&
                                    <div>
                                        <h5 style={{ fontWeight: '600' }}>Description:</h5>
                                        <p style={{ fontSize: 16, fontWeight: '400' }}>{job.description}</p>
                                    </div>
                                }
                                <h5 style={{ fontWeight: '600' }}>Responsibilities:</h5>
                                <ul style={{ padding: 0, lineHeight: 2 }}>
                                    {JSON.parse(job.responsibilities).map((val, i) => (
                                        <li key={i} style={{ fontSize: 16, position: 'relative', listStyle: 'none', display: 'flex', alignItems: 'flex-start' }}>
                                            <TbFlag3Filled style={{ marginRight: '0.5em', marginTop: 10, color: '#f04231' }} />
                                            <div style={{ flex: 1 }}>
                                                {val}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <h5 style={{ fontWeight: '600' }}>Requirements:</h5>
                                <ul style={{ padding: 0, lineHeight: 2 }}>
                                    {JSON.parse(job.requirements).map((val, i) => (
                                        <li key={i} style={{ fontSize: 16, position: 'relative', listStyle: 'none', display: 'flex', alignItems: 'flex-start' }}>
                                            <TbFlag3Filled style={{ marginRight: '0.5em', marginTop: 10, color: '#f04231' }} />
                                            <div style={{ flex: 1 }}>
                                                {val}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                {/* <h5>Company</h5>
                                        <p style={{ fontSize: 16, fontWeight: '400' }}>{v.company_name}</p>
                                        <h5>Contact Email</h5>
                                        <p style={{ fontSize: 16, fontWeight: '400' }}>{v.contact_email}</p> */}

                                <h5 style={{ fontWeight: '600' }}>Work Environment:</h5>
                                <p style={{ fontSize: 16, fontWeight: '400' }}>{job.description2}</p>
                                <h5 style={{ fontWeight: '600' }}>Posted At:</h5>
                                <p style={{ fontSize: 16, fontWeight: '400' }}>{new Date(job.posted_at).toDateString()}</p>
                            </div>
                        )}
                    </Row>
                </Container>

            </section>
            {isLoading &&
                <Uploading />
            }
            <Footer />
        </>
    );
}

export default Jobs;
