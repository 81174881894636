import React from 'react'
// import '../../../src/assets/js/main';


// icons 
import {FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaTwitter } from "react-icons/fa";
function HeaderSocialLink() {
    return (
        <section id="topbar" className="topbar d-flex align-items-center">
            <div className="container d-flex justify-content-center justify-content-md-between">
                <div className="contact-info d-flex align-items-center">
                    <i className="bi bi-envelope d-flex align-items-center"><FaEnvelope /><a href="/">info@Techmazeglobal.com</a></i>
                    <i className="bi bi-phone d-flex align-items-center ms-4"><FaPhone /><span>+92 03402117591</span></i>
                </div>
                <div className="social-links d-none d-md-flex align-items-center">
                    <a href="#" className="twitter"><FaTwitter /> </a>
                    <a href="#" className="facebook"><FaFacebook /></a>
                    <a href="#" className="instagram"><FaInstagram /></a>
                    <a href="#" className="linkedin"><FaLinkedin /></a>
                </div>
            </div>
        </section>
    )
}

export default HeaderSocialLink