import { FaAccusoft, FaDigitalOcean, FaHandsHelping, FaMobileAlt } from "react-icons/fa";
import { MdManageHistory, MdOutlineSecurity, MdSpaceDashboard } from "react-icons/md";
import { TbCloudExclamation } from "react-icons/tb";
import { GrAnalytics } from "react-icons/gr";
import { IoLogoDesignernews } from "react-icons/io";
const services_data = [
    {
        id: 1,
        title: "Custom Software Development",
        description: [
            "Techmaze excels in delivering bespoke software solutions tailored to meet the unique needs of your business. Our custom software development service goes beyond off-the-shelf solutions, ensuring that the software aligns precisely with your goals and processes.",
            "Our seasoned development team is well-versed in the latest technologies and methodologies, allowing us to create innovative and scalable software applications. We begin by conducting a thorough analysis of your business requirements, working closely with your team to understand objectives, challenges, and desired outcomes.",
            "The development process at Techmaze is collaborative and transparent. We keep you involved at every stage, from conceptualization to deployment. This ensures that the final product not only meets but exceeds your expectations, providing a competitive edge in your industry.",
        ],
        icon: <FaAccusoft />,
        image: require('../../src/assets/img/services/service1.jpg'),
    },
    {
        id: 2,
        title: "Web Development",
        description: [
            "Techmaze excels in web development, creating responsive and visually stunning websites and web applications. Our approach focuses on user-centric design, ensuring a seamless and engaging experience across various devices.",
            "From e-commerce platforms to content management systems, we blend creativity with functionality. Our team emphasizes performance optimization, security, and scalability, providing web solutions that not only look good but also perform exceptionally well.",
            "Partner with Techmaze for web development that not only meets industry standards but sets new benchmarks for innovation and user satisfaction.",
        ],
        icon: <MdSpaceDashboard />,
        image: require('../../src/assets/img/services/service2.jpg'),
    },
    {
        id: 3,
        title: "Mobile App Development",
        description: [
            "Techmaze is your go-to partner for cutting-edge mobile app development. Our experienced team specializes in creating user-friendly and feature-rich applications for both iOS and Android platforms.",
            "From concept to deployment, we prioritize a user-centric approach, ensuring that your mobile app not only meets but exceeds user expectations. Whether it's a consumer-facing app or an enterprise solution, we bring innovation and expertise to every project.",
            "Transform your mobile app ideas into reality with Techmaze and make a lasting impact in the fast-paced world of mobile technology.",
        ],
        icon: <FaMobileAlt />,
        image: require('../../src/assets/img/services/service3.jpg'),
    },
    {
        id: 4,
        title: "IT Consulting",
        description: [
            "Techmaze provides strategic IT consulting services to empower your business with the right technology solutions. Our seasoned consultants offer insights and guidance on technology adoption, system integration, and business process improvement.",
            "We believe in a holistic approach to IT consulting, considering not just immediate needs but long-term objectives. Whether you're looking to optimize your existing IT infrastructure or embark on a digital transformation journey, Techmaze is your trusted advisor.",
            "Make informed decisions and drive innovation with Techmaze's comprehensive IT consulting services.",
        ],
        icon: <FaHandsHelping />,
        image: require('../../src/assets/img/services/service4.jpg'),
    },
    {
        id: 5,
        title: "Cloud Computing Services",
        description: [
            "Techmaze specializes in cloud computing services, helping businesses harness the power of the cloud for enhanced scalability, flexibility, and efficiency. Our experts guide clients through the entire cloud journey, from strategy and migration to ongoing management.",
            "Whether you're adopting public, private, or hybrid cloud solutions, we tailor our services to align with your specific business requirements. Experience the benefits of cloud computing with Techmaze as your trusted partner.",
            "Elevate your business to new heights with Techmaze's comprehensive cloud computing services.",
        ],
        icon: <TbCloudExclamation />,
        image: require('../../src/assets/img/services/service5.jpg'),
    },
    {
        id: 6,
        title: "Cybersecurity Solutions",
        description: [
            "Security is paramount in the digital age, and Techmaze is your partner in fortifying your digital assets. Our cybersecurity solutions encompass threat detection, prevention, and incident response to safeguard your business from evolving cyber threats.",
            "With a proactive and comprehensive approach, we identify vulnerabilities, implement robust security measures, and provide ongoing monitoring. Protect your data and maintain the trust of your stakeholders with Techmaze's cybersecurity expertise.",
            "Stay ahead of cyber threats with Techmaze's cutting-edge cybersecurity solutions.",
        ],
        icon: <MdOutlineSecurity />,
        image: require('../../src/assets/img/services/service6.jpg'),
    },
    {
        id: 7,
        title: "Data Analytics and Business Intelligence",
        description: [
            "Unlock the power of data with Techmaze's data analytics and business intelligence services. We help businesses transform raw data into actionable insights, facilitating informed decision-making and strategic planning.",
            "Our team leverages advanced analytics tools and methodologies to uncover trends, patterns, and opportunities within your data. Whether you're looking to enhance operational efficiency or gain a competitive edge, Techmaze is your data-driven partner.",
            "Turn your data into a strategic asset with Techmaze's expertise in data analytics and business intelligence.",
        ],
        icon: <GrAnalytics />,
        image: require('../../src/assets/img/services/service7.jpg'),
    },
    {
        id: 8,
        title: "E-commerce Solutions",
        description: [
            "Techmaze specializes in crafting e-commerce solutions that elevate online businesses. Whether you're launching a new online store or optimizing an existing platform, our team focuses on user experience, security, and scalability.",
            "From seamless transaction processes to engaging storefronts, we ensure that your e-commerce presence aligns with your brand and objectives. Elevate your online business with Techmaze's expertise in e-commerce solutions.",
            "Transform your online presence with Techmaze's tailored e-commerce solutions.",
        ],
        icon: <FaAccusoft />,
        image: require('../../src/assets/img/services/service8.jpg'),
    },
    {
        id: 9,
        title: "UI/UX Design",
        description: [
            "Techmaze places a strong emphasis on user-centric design with our UI/UX design services. We create visually appealing and intuitive interfaces that enhance user experiences and drive engagement.",
            "Our design process involves user research, wireframing, prototyping, and continuous testing to ensure optimal usability. Whether it's a website, mobile app, or software interface, Techmaze designs with your users in mind.",
            "Elevate your digital products with Techmaze's expertise in UI/UX design.",
        ],
        icon: <IoLogoDesignernews />,
        image: require('../../src/assets/img/services/service9.jpg'),
    },
    // Add more service entries as needed
];

export default services_data 