import React from 'react'
import about1 from '../assets/img/about/about1.jpg'
import about2 from '../assets/img/about/about2.jpg'
function About() {
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>About Us</h2>
                    <p>Techmaze, established in 2020, is on a mission to bring your ideas to reality. Our global team of 50+ remote professionals ensures innovation at every step.</p>
                </div>

                <div className="row gy-4">
                    <div className="col-lg-6">
                        <h3>Unlock Innovation with Techmaze: Turning Ideas into Reality.</h3>
                        <img src={about1} className="img-fluid rounded-4 mb-4" alt="Techmaze Office" />
                        <p>Embark on a transformative journey with Techmaze, where visionary ideas meet unparalleled excellence. As a global team of over 50 remote professionals, our mission is to bring your concepts to life through cutting-edge and innovative solutions.</p>
                        <p>Immerse yourself in our professional company culture as we take pride in successfully completing numerous international projects. Techmaze not only delivers solutions but also forges strategic partnerships, aiming for nothing less than excellence. Join us on our ambitious quest to be recognized among the top ten companies globally, reshaping the landscape of technology.</p>
                    </div>
                    <div className="col-lg-6">
                        <div className="content ps-0 ps-lg-5">
                            <div>
                                <p className="fst-italic">
                                    Techmaze - Where Ideas Meet Excellence, and Innovation Knows No Bounds.
                                </p>
                                <ul>
                                    <li><i className="bi bi-check-circle-fill"></i> Crafting solutions with a wealth of global expertise and creative ingenuity.</li>
                                    <li><i className="bi bi-check-circle-fill"></i> Partnering with clients and collaborators alike for truly innovative outcomes.</li>
                                    <li><i className="bi bi-check-circle-fill"></i> Achieving excellence in every project, setting new benchmarks for quality and innovation.</li>
                                </ul>
                                <p>
                                    Techmaze is more than a service provider; we're your dedicated partner in technological advancement. Our commitment to creativity, collaboration, and quality sets us apart, ensuring that each project is not just delivered but exceeds expectations. Join us as we redefine the possibilities of technology, one innovative solution at a time.
                                </p>
                            </div>
                            <div className="position-relative mt-4">
                                <img src={about2} className="img-fluid rounded-4" alt="Techmaze Collaboration" />
                                {/* <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox play-btn"></a> */}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    )
}

export default About