import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    setIsVisible(scrolled > 120);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div
          className="scrolltobottom"
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            zIndex: 1000,
            right: 40,
            bottom: 40,
            backgroundColor: "#ffff",
            width: 50,
            height: 50,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
            display: 'flex',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer',
          }}
        >
          <FaArrowUp size={22} color='#f04231' />
        </div>
      )}
    </>
  );
}

export default ScrollToTopButton;
