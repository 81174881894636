import React, { useState } from 'react'

import stats from '../../assets/img/stats-img.svg'
import About from '../About';
import Services from '../Services';
import Team from '../Team';
import Contact from '../Contact';
import Hero from '../Hero';
import { FaArrowUp } from 'react-icons/fa';
import ScrollToTopButton from '../../components/header_footer/ScrollToTopButton';
import Partner from '../Partner';
import { useParams } from 'react-router-dom';
import Header from '../../components/header_footer/Header';
import Footer from '../../components/header_footer/Footer';
import { Container } from 'react-bootstrap';


function Home() {
    const [service_id, setService_id] = useState(null)
    return (
        <>
            <Header handleOnclick={(id) => setService_id(id)} />
            <Hero />
            {/* main section End */}

            <main id="main">
                <About />
                <Services service_id={service_id} />
                <section id="stats-counter" className="stats-counter">
                    <div className="container" data-aos="fade-up">

                        <div className="row gy-4 align-items-center">

                            <div className="col-lg-6">
                                <img src={stats} alt="" className="img-fluid" />
                            </div>

                            <div className="col-lg-6">

                                <div className="stats-item d-flex align-items-center">
                                    <span className="purecounter">200</span>
                                    <p><strong>Happy Clients</strong> Techmaze is proud to have brought satisfaction and success to 200 clients, delivering solutions that resonate with their visions and goals.</p>
                                </div>

                                <div className="stats-item d-flex align-items-center">
                                    <span className="purecounter">258</span>
                                    <p><strong>Projects</strong> With a dedicated team and a commitment to excellence, Techmaze has successfully completed 258 projects, each a testament to our expertise and collaborative approach.</p>
                                </div>

                                <div className="stats-item d-flex align-items-center">
                                    <span className="purecounter">254</span>
                                    <p><strong>Hours Of Support</strong> At Techmaze, we go beyond project completion. Our team provides a total of 254 hours of dedicated support, ensuring that our clients receive ongoing assistance and expertise whenever needed</p>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>

                {/* <Team />
                <Partner /> */}

                {/* Contats us */}
                <Contact />

                {/* location i frame */}
                <div className="google-map-code">
                    <Container>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3621.0643735251506!2d67.0332286753698!3d24.827472077950876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQ5JzM4LjkiTiA2N8KwMDInMDguOSJF!5e0!3m2!1sen!2s!4v1708694567812!5m2!1sen!2s"
                            width='100%'
                            height="450"
                            frameborder="0"
                            style={{ border: 0, }}
                            allowfullscreen=""
                            aria-hidden="false"
                            tabindex="0"
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        />
                    </Container>
                </div>

            </main >
            <Footer />
            <ScrollToTopButton />

        </>
    )
}

export default Home