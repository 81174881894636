import React from 'react'
import { GoLaw } from "react-icons/go";
import { BsCash, BsEasel, BsGraphUpArrow } from "react-icons/bs";
import hero from '../assets/img/home_side_banner.png'
import { Col } from 'react-bootstrap';

function Hero() {
    return (
        <>
            {/* <Col style={{ textAlign: 'center' }} className='welcome'>
                <h1 className='text-center' style={{fontWeight:'600',fontSize:26}}>Welcom to Techmaz</h1>
            </Col> */}
            <section id="hero" className="hero">
                <div className="container position-relative">
                    <div className="row d-flex hero-container" style={{ height: 400 }}>
                        <div className="col-lg-6 d-flex flex-column justify-content-center text-center text-lg-start">
                            <span style={{fontWeight:'bold'}}>Welcome to Techmaze</span>
                            <h1>Software Development Company</h1>
                            <p>Unlock Business Potential with Our Software Development company and Expert IT Staff Augmentation Services. Our team of skilled professionals brings your unique vision to life through cutting-edge technology solutions. From seamless software development to flexible IT staff augmentation, we empower your organization to thrive in the digital landscape.</p>
                            <div className="d-flex home-button">
                                <a href="#about" className="btn-get-started">Get Started</a>
                                <a href='#contact' className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Contact Us</span></a>
                            </div>
                        </div>
                        <div className="col-lg-6 align-items-center justify-content-center d-flex hero-banner-container">
                            <img src={hero} className="img-fluid hero-banner" alt="banner" />
                        </div>
                    </div>
                </div>

                {/* <div className="icon-boxes position-relative">
                <div className="container position-relative align-items-center justify-content-center">
                <div className="row gy-4 mt-5">
                
                <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
                <div className="icon-box">
                <div className="icon"><i><BsEasel /></i></div>
                <h4 className="title"><a href="" className="stretched-link">Tech Development</a></h4>
                </div>
                </div>
                
                <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="200">
                <div className="icon-box">
                <div className="icon"><i><BsGraphUpArrow /></i></div>
                <h4 className="title"><a href="" className="stretched-link">Customer Insight</a></h4>
                </div>
                </div>
                
                <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <div className="icon-box">
                <div className="icon"><i><BsCash /></i></div>
                <h4 className="title"><a href="" className="stretched-link">Financial Planing</a></h4>
                </div>
                </div>
                
                <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
                <div className="icon-box">
                <div className="icon"><i><GoLaw /></i></div>
                <h4 className="title"><a href="" className="stretched-link">Legal Information</a></h4>
                </div>
                </div>
                
                </div>
                </div>
            </div> */}

            </section >
        </>
    )
}

export default Hero