import React, { useEffect, useState } from 'react';
import HeaderSocialLink from './HeaderSocialLink';
import { FaList } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import logo from '../../assets/img/Techmaze.png';
import services_data from '../../web_data/Web_data';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Header({ handleOnclick }) {
    const [isToggle, setIsToggle] = useState(false);
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    const handleServiceClick = (id) => {
        const serviceSection = document.getElementById('services');
        if (!serviceSection) {
            navigate('/#services')
            handleCloseMobileNav();
            return;
        }

        const dropdownContainer = document.querySelector('.dropdown-inner-container');

        // Store the current max-height and opacity
        const prevMaxHeight = dropdownContainer.style.maxHeight;
        const prevOpacity = dropdownContainer.style.opacity;

        // Remove the class and set max-height to 0
        dropdownContainer.classList.remove('dropdown-inner-container');
        dropdownContainer.style.maxHeight = '0';

        // Scroll to the service section
        serviceSection.scrollIntoView({ behavior: 'smooth' });

        // Navigate to Home with the selected service ID as a URL parameter
        // window.location.href = `/#services`;

        // Reset the max-height and opacity after clicking
        setTimeout(() => {
            dropdownContainer.classList.add('dropdown-inner-container');
            dropdownContainer.style.maxHeight = prevMaxHeight;
            dropdownContainer.style.opacity = prevOpacity;
        }, 1000); // Adjust the timeout as needed

        handleOnclick(id);
        handleCloseMobileNav();
    };


    const handleCloseMobileNav = () => {
        setIsToggle(false);
    };

    return (
        <>
            <HeaderSocialLink />
            <header id="header" className="header d-flex align-items-center">
                <div
                    className={`container-fluid container-xl container d-flex align-items-center justify-content-between ${isToggle ? 'mobile-nav-active' : ''}`}
                >
                    <Link to="/" className="logo d-flex align-items-center"> {/* Use Link component */}
                        <img src={logo} alt="" />
                    </Link>
                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><Link to="/#hero" onClick={handleCloseMobileNav}>Home</Link></li> {/* Use Link component */}
                            <li><Link to="/#about" onClick={handleCloseMobileNav}>About</Link></li> {/* Use Link component */}
                            <li>
                                <div className="dropdown-container">
                                    <Link to="/#services">Services</Link> {/* Use Link component */}
                                    <div className='dropdown-inner-container'>
                                        <div className="dropdown-inner-contant">
                                            {services_data.map((v, i) => (
                                                <a key={i} onClick={() => { handleServiceClick(v.id) }} className='dropdown-element'>{v.title}</a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li><Link to="/#team" onClick={handleCloseMobileNav}>Team</Link></li> {/* Use Link component */}
                            <li><Link to="/#contact" onClick={handleCloseMobileNav}>Contact</Link></li> {/* Use Link component */}
                            <li><Link to="/careers" onClick={handleCloseMobileNav}>Careers</Link></li> {/* Use Link component */}
                        </ul>
                    </nav>
                    <i onClick={() => setIsToggle(!isToggle)} style={{ cursor: 'pointer' }} className={`mobile-nav-toggle mobile-nav-show ${isToggle ? 'd-none' : ''}`} > <FaList /></i>
                    <i onClick={() => setIsToggle(!isToggle)} style={{ cursor: 'pointer' }} className={`mobile-nav-toggle mobile-nav-hide ${isToggle ? '' : 'd-none'}`}><RxCross2 /></i>
                </div>
            </header>
        </>
    );
}

export default Header;
