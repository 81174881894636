import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import services_data from "../web_data/Web_data";
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
function Services({ service_id }) {
  const [service, setService] = useState(null);
  const descriptionRef = useRef(null);

  const onPressServices = (id, title) => {
    const selectedService = services_data.find(item => item.id === id);
    setService(selectedService);
    // Scroll to the description section
  };
  useEffect(() => {
    if (service_id != null) {
      onPressServices(service_id)
    } else {
      setService(null)
    }
  }, [service_id])
  useEffect(() => {
    if (descriptionRef.current) {
      window.scrollTo({
        top: descriptionRef.current.offsetTop + -100,
        behavior: 'smooth',
      });
    }
  }, [service])

  return (
    <section id="services" className="services sections-bg">
      {service == null ? (
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Our Services</h2>
            <p>Empowering success through tailored IT solutions – Techmaze transforms ideas into digital excellence.</p>
          </div>

          <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
            {services_data.map((v, i) => (
              <motion.div key={i} className="col-lg-4 col-md-6" animate={{ opacity: 1, y: 0 }} initial={{ opacity: 0, y: 50 }}>
                <div className="service-item">
                  <div className="icon">
                    <i>{v.icon}</i>
                  </div>
                  <h3>{v.title}</h3>
                  <p>{v.description[0]}</p>
                  <a style={{ cursor: 'pointer' }} onClick={() => onPressServices(v.id, v.title)} className="readmore">
                    Read more <FaArrowRight />
                  </a>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      ) : (
        <motion.div className="container desc" ref={descriptionRef} animate={{ opacity: 1, y: 0 }} initial={{ opacity: 0, y: 50 }}>
          {/* 
          <div className="row gy-4">
            <div className="col-lg-6">
              <h3>Unlock Innovation with Techmaze: Turning Ideas into Reality.</h3>
              <img src={"https://images.unsplash.com/photo-1531973576160-7125cd663d86?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} className="img-fluid rounded-4 mb-4" alt="Techmaze Office" />
              <p>Embark on a transformative journey with Techmaze, where visionary ideas meet unparalleled excellence. As a global team of over 50 remote professionals, our mission is to bring your concepts to life through cutting-edge and innovative solutions.</p>
              <p>Immerse yourself in our professional company culture as we take pride in successfully completing numerous international projects. Techmaze not only delivers solutions but also forges strategic partnerships, aiming for nothing less than excellence. Join us on our ambitious quest to be recognized among the top ten companies globally, reshaping the landscape of technology.</p>
            </div>
          </div> */}

          <Row className="service_detail">
            <Col className="d-flex">
              <img src={service.image} className="service_image img-fluid rounded-4 mb-4" />
            </Col>
            <Col className="">
              <Row className="flex-column">
                <Col className="align-items-center d-flex">
                  <FaArrowLeft size={20} style={{ cursor: 'pointer' }} onClick={() => { setService(null) }} />
                  <h2 style={{ margin: 0, paddingLeft: 20 }}>{service.title}</h2>
                </Col>
                <Col className="pt-4">
                  <p>{service.description.join(' ')}</p>
                  <a href="#contact" className="btn-get-started service_contact_btn mt-2">Contact Now</a>
                </Col>
              </Row>
            </Col>
          </Row>
        </motion.div>
      )}
    </section>
  );
}

export default Services;
