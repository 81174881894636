import React, { useEffect, useState } from 'react';
import Footer from '../../components/header_footer/Footer';
import Header from '../../components/header_footer/Header';
import { Container, Row } from 'react-bootstrap';
import { CiSearch } from "react-icons/ci";
import { TbFlag3Filled } from "react-icons/tb";
import axios from 'axios';
import { base_url } from '../../utils/Constants';
import { FiPlus, FiMinus } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';

function Careers() {
    const navigate = useNavigate()
    const [careers, setCareers] = useState([]);
    const [originalCareers, setOriginalCareers] = useState([]);
    const [openItem, setOpenItem] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const careersData = sessionStorage.getItem('careersData');
        if (careersData) {
            const parsedCareersData = JSON.parse(careersData);
            setCareers(parsedCareersData);
            setOriginalCareers(parsedCareersData); // Store original data
        }
        if (careersData == undefined || careersData == null) {
            setLoading(true)
        }
        axios.get(`${base_url}api/all_post`)
            .then(response => {
                setLoading(false)
                if (response.data && response.data.success) {
                    const fetchedCareers = response.data.data;
                    setCareers(fetchedCareers);
                    setOriginalCareers(fetchedCareers); // Store original data
                    // Store the fetched careers data in sessionStorage
                    sessionStorage.setItem('careersData', JSON.stringify(fetchedCareers));
                }
            })
            .catch(error => {
                setLoading(false)
                console.error('Error fetching data:', error);
            });

    }, []);

    const handleItemClick = (index) => {
        setOpenItem(openItem === index ? null : index);
    };

    const onApply = (id) => {
        navigate(`/jobs/${id}`); // Navigate to "jobs" page with parameter "id"
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearch(value);

        if (value === '') {
            // If search input is empty, show original data
            setCareers(originalCareers);
        } else {
            // Filter the original data based on the search term
            const filteredResults = originalCareers.filter(item =>
                item.title.toLowerCase().includes(value.toLowerCase()) ||
                item.location.toLowerCase().includes(value.toLowerCase())
            );
            setCareers(filteredResults);
        }
    };

    return (
        <>
            <Header />
            <section className='career_section'>
                <div className='career_header'>
                    <Container className='d-flex align-items-center h-100'>
                        <h1>Opportunities don’t<br /> happen, you create them</h1>
                    </Container>
                </div>
                {!loading ?
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="input-group mb-3 bottom-border align-items-center">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" style={{ height: 50 }}>
                                            <CiSearch className="align-middle" size={30} />
                                        </span>
                                    </div>
                                    <input type="text" className="form-control align-middle" placeholder="Search..."
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container mt-5">
                        <div className="row text-center">
                            <span className='loading_post'>Loading....</span>
                        </div>
                    </div>
                }
                <Container className='mt-5 mb-5'>
                    <Row>
                        {careers.map((v, i) => (
                            <div key={i} className='career_container'>
                                <div className='career_accordions' onClick={() => handleItemClick(i)}>
                                    {openItem === i ? <FiMinus size={22} /> : <FiPlus size={22} />}
                                    <div style={{ flex: 1, padding: 10, alignItems: 'center' }} className='d-flex'>
                                        <span className='career_title'>{v.title}</span>
                                        <span className='career_location'>{v.location}</span>
                                    </div>
                                    <a className='apply_now_btn' onClick={() => onApply(v.id)}>Apply Now</a>
                                </div>
                                <div className={`accordion-content ${openItem === i ? 'open' : 'closed'}`}>
                                    <div className='pt-3 pb-3'>
                                        {v.description &&
                                            <div>
                                                <h5 style={{ fontWeight: '600' }}>Description:</h5>
                                                <p style={{ fontSize: 16, fontWeight: '400' }}>{v.description}</p>
                                            </div>
                                        }
                                        <h5 style={{ fontWeight: '600' }}>Responsibilities:</h5>
                                        <ul style={{ padding: 0, lineHeight: 2 }}>
                                            {JSON.parse(v.responsibilities).map((val, i) => (
                                                <li key={i} style={{ fontSize: 16, position: 'relative', listStyle: 'none', display: 'flex', alignItems: 'flex-start' }}>
                                                    <TbFlag3Filled style={{ marginRight: '0.5em', marginTop: 10, color: '#f04231' }} />
                                                    <div style={{ flex: 1 }}>
                                                        {val}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        <h5 style={{ fontWeight: '600' }}>Requirements:</h5>
                                        <ul style={{ padding: 0, lineHeight: 2 }}>
                                            {JSON.parse(v.requirements).map((val, i) => (
                                                <li key={i} style={{ fontSize: 16, position: 'relative', listStyle: 'none', display: 'flex', alignItems: 'flex-start' }}>
                                                    <TbFlag3Filled style={{ marginRight: '0.5em', marginTop: 10, color: '#f04231' }} />
                                                    <div style={{ flex: 1 }}>
                                                        {val}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        {/* <h5>Company</h5>
                                        <p style={{ fontSize: 16, fontWeight: '400' }}>{v.company_name}</p>
                                        <h5>Contact Email</h5>
                                        <p style={{ fontSize: 16, fontWeight: '400' }}>{v.contact_email}</p> */}

                                        <h5 style={{ fontWeight: '600' }}>Work Environment:</h5>
                                        <p style={{ fontSize: 16, fontWeight: '400' }}>{v.description2}</p>
                                        <h5 style={{ fontWeight: '600' }}>Posted At:</h5>
                                        <p style={{ fontSize: 16, fontWeight: '400' }}>{new Date(v.posted_at).toDateString()}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default Careers;
